/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import '~@ctrl/ngx-emoji-mart/picker';

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
/* Custom Css by Hkimi Ridha */

// Add bacground image to body (add new class in balise body)
.custom-bg-app {
  background: url(assets/img/alliance-bg-login-app.jpg) no-repeat;
  background-size: cover;
}
// Add shodow to card page login
.custom-bg-app .card {
  box-shadow: 0 .5rem 1rem rgba(18, 38, 63, 0.2);
  margin: 0 10px;
}
// Add backgrounf color to header card login
.card .bg-primary.bg-soft {
background-color: #08163b !important;
}
// Change color of title card login
.card .text-primary {
  color: #FDB300 !important;
}
// Change color of title card login
.card .text-primary p {
  color: #ffffff !important;
  padding-bottom: 15px;
}
// Change buttom color page login
button.login100-form-btn.loginbtn {
  background: #FDB300 !important;
}
button.login100-form-btn.loginbtn:hover {
  background: #08163b !important;
}
// Fixed position Footer
.footer {
  position: fixed !important;
}
// Change background color of side bar vertical-menu
body[data-sidebar=dark] .vertical-menu, body[data-sidebar=dark] .navbar-brand-box, body[data-sidebar=colored] .vertical-menu, body[data-sidebar=colored] .navbar-brand-box  {
  background: #1a1d38 !important;
}
// Cusrom size icon card dashboerds
.custom-size-icon {
font-size: 42px;
}
.custom-size-title {
  font-size: 24px;
  color: #08163b !important;
  }
  .card-body:hover {
    cursor: pointer;
  }
  // Custom background page Marge
  .custom-bg-marge {
    background-color: #ffffff;
    box-shadow: 0 .5rem 1rem rgba(18, 38, 63, 0.2);
    padding: 30px;
  }
  .custom-btn-valider {
    display: flex;
    justify-content: center;
  }
  .avatar-title {
    background-color: #FDB300 !important;;
}
.table th {
  color: #FDB300;
}
#custom-table-marge {
  background-color: #ffffff;
  box-shadow: 0 .5rem 1rem rgba(18, 38, 63, 0.2);
  padding: 20px;
}
form label mat-label {
  font-size: 15px;
}